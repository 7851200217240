<template>
  <b-card :title="$t('termsInfo')">
    <b-form @submit.prevent>
      <b-row>
        <b-col lg="3" sm="6">
          <b-form-group :label="$t('cash')" label-for="mc-first-name">
            <b-form-checkbox v-model="terms.pesinactive">
              <b-form-input
                id="mc-first-name"
                v-model="terms.pesin"
                :placeholder="$t('cash')"
              />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col lg="3" sm="6">
          <b-form-group
            :label="$t('termNumber', { number: 1 })"
            label-for="mc-last-name"
          >
            <b-form-checkbox v-model="terms.vade1active">
              <b-form-input
                id="mc-last-name"
                v-model="terms.vade1"
                :placeholder="$t('termNumber', { number: 1 })"
              />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col lg="3" sm="6">
          <b-form-group
            :label="$t('termNumber', { number: 2 })"
            label-for="mc-last-name"
          >
            <b-form-checkbox v-model="terms.vade2active">
              <b-form-input
                id="mc-last-name"
                v-model="terms.vade2"
                :placeholder="$t('termNumber', { number: 2 })"
              />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col lg="3" sm="6">
          <b-form-group
            :label="$t('termNumber', { number: 3 })"
            label-for="mc-last-name"
          >
            <b-form-checkbox v-model="terms.vade3active">
              <b-form-input
                id="mc-last-name"
                v-model="terms.vade3"
                :placeholder="$t('termNumber', { number: 3 })"
              />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col lg="3" sm="6">
          <b-form-group
            :label="$t('termNumber', { number: 4 })"
            label-for="mc-last-name"
          >
            <b-form-checkbox v-model="terms.vade4active" size="md">
              <b-form-input
                id="mc-last-name"
                v-model="terms.vade4"
                :placeholder="$t('termNumber', { number: 4 })"
              />
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col sm="12">
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            @click="updateTerms"
          >
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCard,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BFormCheckbox,
    BCard,
  },
  data: function () {
    return {
      terms: {},
    };
  },
  async mounted() {
    var response = await this.$http.get(
      `/Companies/GetCompanyTermNames/${getUserData().companyid}`
    );
    var isValid = response.data["status"].toString() == "OK";
    if (isValid) this.terms = response.data.resultdata[0];
  },
  methods: {
    async updateTerms() {
      var result = await this.$http.put(
        `/Companies/UpdateCompanyTermNames/${getUserData().companyid}`,
        JSON.stringify(this.terms)
      );
      var isOK = result.status == 200;
      if (isOK)
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("updated", { type: this.$t("termInfo") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notUpdated", { type: this.$t("termInfo") }),
          },
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
